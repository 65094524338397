import {environment} from '../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {SelectOption} from '../../model/form/select-option.model';
import {HttpClient} from '@angular/common/http';
import {ProgramDTO} from '../../model/dto/program.dto';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProgramWebservice {
  public schoolLevelsSubject: Subject<SelectOption[]>;
  public schoolLevelsArray: SelectOption[];

  private readonly root = `${environment.envApiUrl}/programs`;

  constructor(private readonly http: HttpClient) {}

  getProgram(id: number) {
    // Liste des events d'un program
    return this.http.get(`${this.root}/${id}`);
  }

  getPrograms(): Observable<ProgramDTO[]> {
    // Liste tous les programmes
    return this.http.get(`${this.root}`).pipe(map((value) => value as ProgramDTO[]));
  }

  getAllPrograms(): Observable<ProgramDTO[]> {
    // Liste tous les programmes
    return this.http.get(`${this.root}/all`).pipe(map((value) => value as ProgramDTO[]));
  }

  getProgramsWithActivityTypes() {
    return this.http.get(`${this.root}/withActivityTypes`);
  }

  getProgramsActives() {
    // Liste tous les programmes
    return this.http.get(`${this.root}/actives`);
  }

  getEvents(id: number) {
    // Liste tous les évènements pour un programme
    return this.http.get(`${environment.envApiUrl}/Events/program/${id}`);
  }

  emitSchoolLevelsSubject(): void {
    this.schoolLevelsSubject.next(this.schoolLevelsArray);
  }
}
